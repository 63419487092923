<template>
  <div class="content-container">
    <div class="center-container">
      <p class="title">添加行为数据</p>
      <div class="action-bar">
        <div class="action-bar-left">
          <Form :label-width="90">
            <Form-item label="开始时间：">
              <DatePicker type="datetime" format="yyyy-MM-dd HH:mm" @on-change="handleChange" placeholder="选择时间" style="width: 160px"></DatePicker>
            </Form-item>
          </Form>
        </div>
        <div class="action-bar-right">
          <Button type="primary" @click="isOver24Hours = !isOver24Hours">大于24小时请点击</Button>
        </div>
      </div>
      <div class="time-panel">
        <div class="time-gap" v-for="(time, index) in timeGap" :key="index">
          <Button :disabled="handleCompare(time)" :type="result[time] ? 'primary' : 'ghost'" @click="handleClickTime(time)">{{time}}</Button>
        </div>
      </div>
      <div class="time-panel" v-if="isOver24Hours">
        <div class="time-gap" v-for="(time, index) in timeGap" :key="index">
          <Button :disabled="handleCompare2(time)" :type="result[time] ? 'primary' : 'ghost'" @click="handleClickTime(time)">{{time}}</Button>
        </div>
      </div>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="$router.back()">返回</Button>
        <Button type="success" size="large" @click="handleSubmit">提交</Button>
      </div>
    </div>
    <Modal
      v-model="showModal"
      :title="checkedTime"
      @on-ok="onOk"
      @on-visible-change="onClose"
      class-name="vertical-center-modal">
      <Form :label-width="100" v-for="(value, i) in temp" :key="i">
        <Form-item :label="'行为' + (i + 1) + '：'">
          <Select v-model="value.behavior" placeholder="请选择" @on-change="changeBehavior(i)">
            <Option :value="v" v-for="v in behaviorList" :key="v">{{v}}</Option>
          </Select>
        </Form-item>
        <Form-item label="内容：">
          <Select v-model="value.content" placeholder="请选择">
            <Option :value="v.name" v-for="v in behaviorContentList[i]" :key="v.name">{{v.name}}</Option>
          </Select>
        </Form-item>
        <Form-item label="行动时间：">
          <TimePicker type="time" v-model="value.time" placeholder="选择时间" format="HH:mm" style="width: 160px"></TimePicker>
        </Form-item>
      </Form>
      <Button type="primary" @click="handleAdd" style="margin-left: 20px;">+</Button>
    </Modal>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			behaviorList: [],
			subBehavior: {},
			behaviorContentList: [[], [], [], []],
			showModal: false,
			timeGap: [
				'00:00',
				'01:00',
				'02:00',
				'03:00',
				'04:00',
				'05:00',
				'06:00',
				'07:00',
				'08:00',
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
				'19:00',
				'20:00',
				'21:00',
				'22:00',
				'23:00',
			],
			isOver24Hours: false,
			beginTime: '',
			temp: [
				{
					behavior: '',
					content: '',
					time: '',
				},
			],
			data: {},
			checkedTime: '',
			result: {},
		};
	},
	created() {
		this.getBehavior();
	},
	methods: {
		getBehavior() {
			MemberService.getBehaviorList().then((data) => {
				data.forEach((item) => {
					this.behaviorList.push(item.name);
					this.subBehavior[item.name] = item.zl;
				});
			});
		},
		handleChange(time) {
			this.beginTime = time;
		},
		handleCompare(time) {
			if (!this.beginTime) return true;
			return time.split(':')[0] < this.beginTime.slice(11).split(':')[0];
		},
		handleCompare2(time) {
			if (!this.beginTime) return true;
			return time.split(':')[0] >= this.beginTime.slice(11).split(':')[0];
		},
		handleClickTime(time) {
			this.checkedTime = time;
			// 如果数据已经存在, 根据选择的行为匹配内容选择项
			if (this.result[time]) {
				this.temp = this.result[time];
				for (let i = 0, l = this.temp.length; i < l; i++) {
					this.behaviorContentList.splice(i, 1, this.subBehavior[this.temp[i]['behavior']]);
				}
			}
			this.showModal = true;
		},
		changeBehavior(index) {
			this.behaviorContentList.splice(index, 1, this.subBehavior[this.temp[index].behavior]);
			this.temp[index].content = '';
		},
		handleAdd() {
			if (this.temp.length < 4) {
				this.temp.push({
					behavior: '',
					content: '',
					time: '',
				});
			} else {
				this.$Message.warning('最多只能记录 4 个行为');
			}
		},
		onOk() {
			this.result[this.checkedTime] = this.temp;
		},
		onClose(visible) {
			if (!visible) {
				this.behaviorContentList = [[], [], [], []];
				this.checkedTime = '';
				this.temp = [
					{
						behavior: '',
						content: '',
						time: '',
					},
				];
			}
		},
		handleSubmit() {
			if (Object.keys(this.result).length === 0) {
				this.$Message.warning('请填写行为记录');
				return;
			}
			const params = {
				member_id: this.$route.params.member_id,
				xm: 2,
				sjrq: this.beginTime.substring(0, 10),
				nr: JSON.stringify(this.result),
			};
			MemberService.submitECGData(params).then(() => {
				this.$Message.success('提交成功');
				this.$router.back();
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-container {
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  .center-container {
    width: 1000px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
  }
  .action-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 52px;
  }
  .time-panel {
    display: flex;
    flex-wrap: wrap;
    border: 1px #eee solid;
    padding: 10px 0;
    margin-bottom: 20px;
    .time-gap {
      flex: 16.6667%;
      text-align: center;
      margin: 10px 0;
    }
  }
}
</style>
